<template>
  <div>
    <div class="px-3 py-2" :style="`direction:${lang.dir}`">
      <b-sidebar
        aria-labelledby="sidebar-no-header-title"
        width="99vw"
        no-header
        id="car_contract_view"
        backdrop
        style="direction: ltr"
        right
        title="add order"
        shadow
      >
        <template #default="{ hide }">
          <div class="m-1 text-start" :style="`direction:` + $parent.lang.dir">
            <div
              style="
                width: 150px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span style="font-size: 0.8em"> {{ lang.add_new_card }} </span>
            </div>
            <div
              @click="hide"
              id="vproconclose"
              style="
                width: 130px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ $parent.lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row style="direction: rtl" class="m-2">
            <v-col cols="12">
              <div
                class="backBlack"
                style="padding: 0px; width: 100%; text-align: center"
              >
                <!-- <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:blue !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" v-b-toggle.add_gallery>{{lang.attachement}}</b-button> 
                                <b-button style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:150px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" v-b-toggle.add_addpayment>{{lang.make_payment}}</b-button>  -->
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 130px;
                    background: darkmagenta !important;
                    font-size: 1.1rem;
                    color: #fff;
                    display:none
                  "
                  @click="addNote()"
                  v-b-toggle.add_car_contract_note
                  >{{ lang.add_notes }}</b-button
                >
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 155px;
                    background: red !important;
                    font-size: 1.1rem;
                    color: #fff;"
                  @click="addTask()"
                  v-b-toggle.addContractCard
                  >{{lang.add_new_card}}</b-button
                >
                <b-button
                  class="mytab1 btn btn-sm btn-default m-2"
                  style="
                    border: 2px solid #fff !important;
                    width: 155px;
                    background: blue !important;
                    font-size: 1.1rem;
                    color: #fff;
                  "
                  @click="editCus()"
                  v-b-toggle.addContractCard
                  >{{ lang.update }}</b-button
                >
                
              </div>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.contract_id }}</th>
                            <td>{{ project.id }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.contract_date }}</th>
                            <!-- <td>{{ project.contract_date.substr(0,10) }}</td> -->
                            <td>{{ project.contract_date }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.start_date }}</th>
                            <td>{{ project.start_date }}</td>
                            <!-- <td>{{ project.start_date.substr(0,10) }}</td> -->
                        </tr>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.expire_date }}</th>
                            <td>{{ project.expire_date }}</td>
                            <!-- <td>{{ project.expire_date.substr(0,10) }}</td> -->
                            <th class="backBlack" :style="`width:100px`">{{ lang.mobile }}</th>
                            <td>{{ project.mobile }}</td>
                            <th class="backBlack" :style="`width:100px`">{{ lang.customer_name }}</th>
                            <td>{{ project.company_name }}</td>
                        </tr>
                    </thead>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" :style="`width:100px`">{{ lang.total }}</th>
                            <th class="backBlack" :style="`width:100px`">{{ lang.vat }}</th>
                            <th class="backBlack" :style="`width:100px`">{{ lang.ftotal }}</th>
                        </tr>
                        <tr>
                            <td>{{ project.total }}</td>
                            <td>{{ project.vat }}</td>
                            <td>{{ project.ftotal }}</td>
                        </tr>
                    </thead>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" style="width:80px;">{{ lang.item_code }}</th>
                            <th class="backBlack">{{ lang.item_name }}</th>
                            <th class="backBlack">{{ lang.item_price }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.items" :key="index">
                            <td>{{ item.item_code }}</td>
                            <td>{{ item.item_name }}</td>
                            <td>{{ item.item_price }}</td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-simple-table>
                    <thead>
                        <tr>
                            <th class="backBlack" style="width:80px;">{{ lang.plate_number }}</th>
                            <th class="backBlack">{{ lang.vin_number }}</th>
                            <th class="backBlack">{{ lang.model }}</th>
                            <th class="backBlack">{{ lang.car_year }}</th>
                            <th class="backBlack">{{ lang.meter_car }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in project.cars" :key="index">
                            <td>{{ item.plate_number }}</td>
                            <td>{{ item.vin }}</td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.manufacturing_year }}</td>
                            <td>{{ item.meter_car }}</td>
                        </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center backBlack">{{ lang.date }}</th>
                        <th class="text-center backBlack">{{ lang.total }}</th>
                        <th class="text-center backBlack">{{ lang.vat }}</th>
                        <th class="text-center backBlack">{{ lang.ftotal }}</th>
                        <th class="text-center backBlack">{{ lang.added_by }}</th>
                        <th class="text-center backBlack">{{ lang.print }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in project.cards" :key="index">
                        <td>{{ item.created_date.substr(0,10) }}</td>
                        <!-- <td>{{ item.created_date.substr(0,10) }}</td> -->
                        <td>{{ item.total }}</td>
                        <td>{{ item.vat }}</td>
                        <td>{{ item.ftotal }}</td>
                        <td>{{ item.created_by }}</td>
                        <td class="text-center backGreen" style="width:80px;color:#fff !important;cursor:pointer" @click="printPDF(item.id)">{{ lang.print }}</td>
                    </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
              <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center backBlack">{{ lang.date }}</th>
                        <th class="text-center backBlack">{{ lang.total }}</th>
                        <th class="text-center backBlack">{{ lang.vat }}</th>
                        <th class="text-center backBlack">{{ lang.ftotal }}</th>
                        <th class="text-center backBlack">{{ lang.add_by }}</th>
                        <th class="text-center backBlack">{{ lang.print }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in project.invoices" :key="index">
                        <td>{{ item.created_by }}</td>
                        <td>{{ item.total }}</td>
                        <td>{{ item.vat }}</td>
                        <td>{{ item.ftotal }}</td>
                        <td></td>
                    </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </template>
      </b-sidebar>
    </div>
    
    <addCarContractCard ref="addCarContractCard" />
  </div>
</template>

<script>
import axios from "axios";
import addCarContractCard from "@/components/addCarContractCard.vue";
export default {
  components: {
    addCarContractCard,
    
  },
  data() {
    return {
      id: 0,
      project: {}
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    is_mobile: function(){
        return false;
    },
    Statics: function () {
      let t = {
        crmActions: {},
        crmStatus: {},
        crmType: {},
      };
      for (let i = 0; i < this.staticOption.length; i++) {
        if (this.staticOption[i].option_phrase == "crmActions") {
          t.crmActions[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        } else if (this.staticOption[i].option_phrase == "crmStatus") {
          t.crmStatus[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        } else if (this.staticOption[i].option_phrase == "crmType") {
          t.crmType[this.staticOption[i].value] = {
            id: this.staticOption[i].value,
            name_ar: this.staticOption[i].name_ar,
            name_en: this.staticOption[i].name_en,
            bgColor: this.staticOption[i].bgColor,
            color: this.staticOption[i].color,
          };
        }
      }
      return t;
    },
  },
  methods: {
    
    addNote() {
      this.$refs.addnotes.crm_id = this.id;
    },
    addTask() {
      this.$refs.addCarContractCard.updateIt(this.project);
    },
    printPDF(id) {
      // if(navigator.share){
      //     navigator.share({
      //         files: [new File([this.readFile('../api/print.php?invid='+id)],'file.pdf',{type: Blob.type})],
      //         title: '',
      //         text: '',
      //     })
      // }else{
    //   let pdfFrame = document.body.appendChild(document.createElement("iframe"));
    //   pdfFrame.style.display = "none";
    //   pdfFrame.src = "../api/print.php?contractJob=" + id;
    //   pdfFrame.onload = () => void pdfFrame.contentWindow.print();
      // }
      window.open("../api/print.php?contractJob=" + id,"_blank")
    },
    getContracts(id) {
      if (id == 0) {
        return false;
      }
      this.id = id;
        const post = new FormData(); 
        post.append("type","getCarContracts");
        post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append("data[id]",id)
        axios.post(
            this.$SAMCOTEC.r_path,post
        ).then((response) => {
            if(response && response.data && response.data.results && response.data.results.length != 0){
                this.project = response.data.results.data[0];
            }
        })
    },
    doComputer(id) {
      if (id == 0) {
        return this.lang.no_devices;
      } else if (id == 1) {
        return this.lang.yes_19;
      } else if (id == 2) {
        return this.lang.yes_24;
      }
    },
    async getStaticOp() {
      let post = new FormData();
      let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
      const path = this.$router.currentRoute.path;
      post.append("type", "getStaticOptions");
      post.append("auth", cook);
      post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
      post.append("data[test]", "1");
      const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post);
      if (response && response.data) {
        this.staticOption = response.data.results.data;
      }
    },
  },
  created() {
    this.getStaticOp();
  },
};
</script>
